document.addEventListener("turbolinks:load", () => {
  const btn = document.querySelector("#navbar-btn-text")
  const main = document.querySelector("main#content")

  btn.addEventListener("click", () => {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('fsz='))
      ?.split('=')[1]

    // 5y: 60*60*24*365*5 = 157680000
    if (main.style.fontSize == "") {
      document.cookie = "fsz=1; max-age=157680000; path=/"
      main.style.fontSize = "12px"
    }
    else {
      document.cookie = "fsz=; path=/"
      main.style.fontSize = ""
    }
    
  })
})