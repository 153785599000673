// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// carregar estils amb webpack
import "../stylesheets/application"

import * as bootstrap from 'bootstrap'
require("@popperjs/core")
import "bootstrap-icons/font/bootstrap-icons.scss"

import flatpickr from 'flatpickr';
import "flatpickr/dist/flatpickr.min.css"
import { Catalan } from "flatpickr/dist/l10n/cat.js"

require('packs/data-confirm-modal')
require('packs/taula_click')
require('packs/navbar-btn-expand')
require('packs/navbar-btn-text')
require('packs/navbar-actiu')
require('packs/header-informacio')

document.addEventListener("turbolinks:load", () => {
  // Both of these are from the Bootstrap 5 docs
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  flatpickr(".datepicker", {
    "locale" : Catalan,
    "dateFormat": "Y-m-d",
    "allowInput" : true,
    "altInput" : true,
    "altFormat" : "d/m/Y",
  })

  dataConfirmModal.setDefaults({
    title: 'Confirmeu l\'acció',
    commit: 'Continuar',
    cancel: 'Cancel·lar'
  });

})

Rails.start()
Turbolinks.start()
ActiveStorage.start()
