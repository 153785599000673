document.addEventListener("turbolinks:load", () => {

  $("tr[data-link]").on("click", (ev) => {
    if( $(ev.target).parents('.taula-ico').length > 0 || $(ev.target).parents('.taula-link').length > 0 ) {
      return
    } else {
      location.href = ev.delegateTarget.dataset.link + '/edit'
    }
  })

  /* -------------------------------------------- versió no jQuery 
  const parent_exempt_redir = (elem) => {
    let b = false
    while(elem = elem.parentElement) {
      if(elem.matches('.taula-link') || elem.matches('.taula-ico')) {
        b = true
        break
      }
    }
    return b
  }

  const elems_ro = document.querySelectorAll("tr[data-link-ro]")
  elems_ro.forEach(el => {
    el.addEventListener("click", (ev) => {
      if(parent_exempt_redir(ev.target)) {
        return
      }
      if (ev.ctrlKey) {
        location.href = ev.delegateTarget.dataset.linkRo + '/edit'
      } else {
        location.href = ev.delegateTarget.dataset.linkRo
      }
    })
  })
  */

  $("tr[data-link-ro]").on("click", (ev) => {
    if( $(ev.target).parents('.taula-ico').length > 0 || $(ev.target).parents('.taula-link').length > 0 ) {
      return
    }
    if (ev.ctrlKey) {
      // location.href = ev.delegateTarget.dataset.linkRo + '/edit'
      window.open(ev.delegateTarget.dataset.linkRo)
    } else {
      location.href = ev.delegateTarget.dataset.linkRo
    }
  })

  $("tr[data-link-ro-ext]").on("click", (ev) => {
    if( $(ev.target).parents('.taula-ico').length > 0 || $(ev.target).parents('.taula-link').length > 0 ) {
      return
    }
    if (ev.ctrlKey) {
      window.open(ev.delegateTarget.dataset.linkRoExt + '/edit', '_blank');
    } else {
      window.open(ev.delegateTarget.dataset.linkRoExt, '_blank');
    }
  })

})
